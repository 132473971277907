import React, { useState, useEffect} from 'react';
import styled from '@emotion/styled';
import useProfiles from '../hooks/useProfiles';
import Image from 'gatsby-image';
import { Link } from 'gatsby';
import urlSlug from 'url-slug';
import useFilter from '../hooks/useFilter';
import useHomeMetadata from '../hooks/useHomeMetadata';
import Seo from './seo'


const ProfilesContent = styled.ul`
    max-width: 1200px;
    width: 95%;
    margin: 4rem auto 2rem auto;

    @media (min-width: 480px) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        row-gap: 2rem;
        column-gap: 2rem;
    }

    @media (min-width: 768px) {
        grid-template-columns: repeat(3, 1fr);
    }
`;

const Card = styled.div`
    border: 1px solid #E1E1E1;
    background-color: #313282;
   
`;

const NotFoundCard = styled.div`
    max-width: 1200px;
    width: 95%;
    border: 1px solid #E1E1E1;
    background-color: #313282;
    margin: 4rem auto 2rem auto;
    text-align: center;
    p {
        padding-top: 1rem;
        font-size: 1.6rem;
        color: #ffcccc;    
    } 
`;


const DetailContent = styled.div`
    padding: 2rem;
     h3{
        font-family: 'Lato', sans-serif;
        margin: 0 0 2rem 0;
        font-size: 2.4rem;
        color: #ffcccc;  
    }
    p{
        font-size: 1.2rem;
        color: #ffcccc;    
    } 
`;

const ProfileLink = styled(Link)`
    img {
        max-width: 100%;
    }
`;


const Profiles = ({ lan }) => {
    const profileList = useProfiles();
    const metadata = useHomeMetadata();
    
    const [ profiles ] = useState(profileList);
    const [ initalLoad, setInitalLoad ] = useState(true);
    const [ nextCount, setNextCount ] = useState(0);
    const [ filteredProfiles, setFilteredProfiles ] = useState([]);
    const [ scrolledProfiles, setScrolledProfiles ] = useState([]);
    const { skinColor, hairColor, boobSize, contexture, eyeColor, assSize, city, FilterUI } = useFilter();

    useEffect(() => {
        if(initalLoad){
            console.log('primera carga')
            const profileSize = profiles.length;
            if( profileSize >= 3 ){
                setScrolledProfiles(profiles.slice(0,6));
                setFilteredProfiles(profiles.slice(0,6)); 
                setNextCount(6);   
            } else {
                setScrolledProfiles(profiles.slice(0,profileSize));
                setFilteredProfiles(profiles.slice(0,profileSize)); 
            }
            setInitalLoad(false);
        } else {
            const handleScroll = () => {
                const scrolledY = window.scrollY;
                const scrollable = document.documentElement.scrollHeight - window.innerHeight;
                const isScrolled = (Math.floor(scrolledY) + 2) >= scrollable;

                if(isScrolled){
                    const currentCount = nextCount;
                    console.log('next count', currentCount);
                    if(currentCount === profiles.length){
                        console.log('complete array');
                    } else if(currentCount + 3 > profiles.length) {
                        console.log('last iteration');
                        let loadMore = profiles.slice(currentCount, profiles.length);
                        let newArray = scrolledProfiles;  
                        loadMore.map( more => ( newArray.push(more)));
                        setScrolledProfiles(newArray);
                        setNextCount(profiles.length);
                    } else {
                        console.log('common iteration');
                        let loadMore = profiles.slice(currentCount, currentCount + 3);
                        let newArray = scrolledProfiles;
                        loadMore.map( more => ( newArray.push(more)));
                        setScrolledProfiles(newArray);
                        setNextCount(currentCount + 3);
                    }
                }
            };
            document.addEventListener('scroll', handleScroll, { passive: true });
            
            if( skinColor || hairColor || boobSize || contexture || eyeColor || assSize || city ){
                const filteredSkinColor  = skinColor ? profiles.filter( profile => profile.skinColor && ( lan === 'es' ? profile.skinColor.name : profile.skinColor.en_name )  ===  skinColor ) : profiles;
                const filteredHairColor  = hairColor ? filteredSkinColor.filter( profile => profile.hairColor && ( lan === 'es' ? profile.hairColor.name : profile.hairColor.en_name ) ===  hairColor ) : filteredSkinColor;
                const filteredBoobSize   = boobSize ? filteredHairColor.filter( profile => profile.boobSize && ( lan === 'es' ? profile.boobSize.name : profile.boobSize.en_name) ===  boobSize ) : filteredHairColor;
                const filteredContexture = contexture ? filteredBoobSize.filter( profile => profile.contexture && ( lan === 'es' ? profile.contexture.name : profile.contexture.en_name ) ===  contexture ) :  filteredBoobSize;
                const filteredEyeColor   = eyeColor ? filteredContexture.filter( profile => profile.eyeColor && ( lan === 'es' ? profile.eyeColor.name : profile.eyeColor.en_name ) ===  eyeColor ) : filteredContexture;
                const filteredAssSize    = assSize ? filteredEyeColor.filter( profile => profile.assSize && ( lan === 'es' ? profile.assSize.name : profile.assSize.en_name ) ===  assSize ) : filteredEyeColor;
                const filteredCity       = city ? filteredAssSize.filter( profile => profile.city && ( lan === 'es' ? profile.city.name : profile.city.en_name ) ===  city ) : filteredAssSize;
                const filtered = filteredCity;
                
                if(filtered){
                    setFilteredProfiles(filtered);
                }
            } else {
                //console.log(scrolledProfiles);
                setFilteredProfiles(scrolledProfiles);
            }
    
            return () => {
                document.removeEventListener('scroll', handleScroll, { passive: true });    
            }

        }
        
    }, [ skinColor, hairColor, boobSize, contexture, eyeColor, assSize, city, profiles, scrolledProfiles, nextCount, initalLoad, lan ])

    
    
    

    return (
        <>
            { FilterUI( lan ) }
            <Seo 
                title={ lan === 'es' ? metadata[0].seo_title : metadata[0].en_seo_title }
                keywords={ lan === 'es' ? metadata[0].seo_keywords : metadata[0].en_seo_keywords  }
                description={ lan === 'es' ? metadata[0].seo_description : metadata[0].en_seo_description }
            />
            { 
                filteredProfiles.length > 0 ?
                    <ProfilesContent>
                        {filteredProfiles.map( profile => (
                            <Card key={profile.id}>
                                <ProfileLink to={ (lan === 'es' ? '/dama-de-compañia-' : '/companions-ladies-') + urlSlug(profile.title) + '/' }>
                                    <Image fluid={profile.picture.sharp.fluid}/>
                                </ProfileLink>
                                <DetailContent >
                                    <h3>{profile.title}</h3>
                                    <p>{getShotDescription( lan === 'es' ? profile.description : profile.en_description)}</p>
                                </DetailContent>
                            </Card>
                        ))}
                    </ProfilesContent>
                :
                    <NotFoundCard>
                        {
                            lan === 'es' ?
                                <p>Vaya!!! no encontramos tu acompañante perfecta pero tenemos muchos otros perfiles que pueden gustarte</p>
                            :
                                <p>Wow !!! We can't find your perfect companion but we have many other profiles that you may like</p>
                        }
                    </NotFoundCard>
            }
        </>
    );
} 
export default Profiles;


function getShotDescription( description ){
    const ptag = description !== null && description !== '' ? description.split(' ') : '';
    if(ptag.length > 0 ){
        return ptag.length > 30 ? ptag.slice( 0, 30).join(' ') + '  ...' : ptag.slice( 0, ptag.length ).join(' ');
    }else{
        return '';
    }
}